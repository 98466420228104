/* For positioning info circle */
.timeline-element-custom {
  position: relative;
}

.timeline-element-custom .tooltip-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
}

a.location-info-a {
  text-decoration: none;
}

.timeline-element-custom .tooltip-wrapper .location-info-tooltip {
  max-width: 500px;
}

.timeline-element-custom .tooltip-wrapper .location-info-tooltip > p {
  color: white;
  margin-top: 0;
  font-size: 1.1em;
}

/* End */

.timeline-element-custom .vertical-timeline-element-content {
  background: #f4f4f4;
}

.vertical-timeline::before {
  background: #dadada;
}

.vertical-timeline-element.vertical-timeline-element--left .vertical-timeline-element-date {
  left: 140% !important;
}

.vertical-timeline-element-content::before {
  border-right-color: #f4f4f4;
}

.img_logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  font-size: 1.4em;
  line-height: 1.75;
}


@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    left: 140%;
  }
  .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date,
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date {
    right: 140%;
  }

  .vertical-timeline--two-columns .vertical-timeline-element-content::before {
    border-left-color: #f4f4f4;
  }

  .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content::before,
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content::before {
    border-right-color: #f4f4f4;
  }

  .img_logo {
    width: 60px;
    height: 60px;
    font-size: 2em;
  }
}


/* .location-info-a {
  text-decoration: none !important;
} */
