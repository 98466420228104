
/* Make there always be three columns */
.mini-posts {
	display: -moz-flex;
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	-moz-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: calc(100% + 2em);
}

	.mini-posts > * {
		margin: 2em 2em 0 0;
		width: calc(33% - 2em);
	}

	.mini-posts > :nth-child(-n + 3) {
		margin-top: 0;
	}


@media screen and (max-width: 800px) {

	.mini-posts > * {
		margin: 2em 2em 0 0;
		width: calc(50% - 2em);
	}

	.mini-posts > :nth-child(-n + 3) {
		margin-top: 2em;
	}

	.mini-posts > :nth-child(-n + 2) {
		margin-top: 0;
	}

}

@media screen and (max-width: 480px) {

	.mini-posts {
		display: block;
		width: 100%;
	}

		.mini-posts > * {
			margin: 0 0 2em 0;
			width: 100%;
		}

}
